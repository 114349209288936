import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {loadStripe} from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { H2, P } from '../../theme/text';
import { appConfig } from '../../appConfig';
import VippsButton from './VippsButton';

const CheckoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`

const StripeCheckoutContainer = styled.div`
    width: 100%;
`

const stripeLiveKey = "pk_live_51PjeIyP8lgqYwPlPLXWp8MoQsZTqo9kMOBk5mwfLtMl1kMkZ7omPvFl7ZFGaQba8t48B5aVmzMlgXaXvI7u9LySM00PD4tPb4O";
const stripeTestKey = "pk_test_51PjeIyP8lgqYwPlP2XmU254RFKLT5mQGw09MaNAlP3F6mAzKV5SRPCEUFI9OjPaY4TRJ7qZdNIRBKPy0dFso8Ou900dpXHmzO1";


var stripePromise = null;
if (appConfig.env === 'prod') {
    stripePromise = loadStripe(stripeLiveKey);
} else {
    stripePromise = loadStripe(stripeTestKey);
}


const Checkout = (props) => {

    const [clientSecret, setClientSecret] = useState(null);

    useEffect(() => {
        setClientSecret(props.clientSecret);
    }, [props.clientSecret])
    
    const options = {clientSecret};

    return(
        <CheckoutContainer id="checkout">
            <H2>Takk for at du benytter Foreldrepenger.no!</H2>
            <P style={{fontSize:"18px"}}>
                Kun ett steg igjen, vi sender deg permisjonsplanen din straks!
            </P>
            {/*<VippsButton product={props.product}/>
            <P style={{fontSize:"16px"}}>
                eller bruk kort
            </P>*/}
            <StripeCheckoutContainer>
                <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={options}
                >
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            </StripeCheckoutContainer>
        </CheckoutContainer>
    )
};

export default Checkout;