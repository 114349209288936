
import React, { useEffect, useState } from "react";
import ResultContainer from "../containers/ResultContainer";
import { perm80Grenser, perm100Grenser } from "../utils/permisjonsUtils";
import { H4, H5, LI, UL, P } from "../theme/text";

// TODO: Fikse i funksjoner så det tar høyde for 80 og  nice

const SummaryCombined = (props) => {

    return(
        <ResultContainer>
            <H4 style={{fontWeight: "bold"}}>Oppsumering med 100% vs 80% lønn</H4>
            <br/>
            <H4>Permisjonsfordeling</H4>
            <H5 style={{margin: "0px"}}>Ved 100% lønnsutbetaling får:</H5>
            <UL style={{margin: "6px"}}>
                <LI>Mor får {props.perm100.ukerMor} uker permisjon</LI>
                <LI>Far for {props.perm100.ukerFar} uker permisjon</LI>
                <LI>Totalt får dere {(props.perm100.ukerFar + props.perm100.ukerMor)} uker permisjon</LI>
            </UL>
            <H5 style={{margin: "0px"}}>Ved 80% lønnsutbetaling får:</H5>
            <UL style={{margin: "6px"}}>
                <LI>Mor får {props.perm80.ukerMor} uker permisjon</LI>
                <LI>Far for {props.perm80.ukerFar} uker permisjon</LI>
                <LI>Totalt får dere {(props.perm80.ukerFar + props.perm80.ukerMor)} uker permisjon</LI>
            </UL>
            <br/>
            <H4>Tid med barnet</H4>
            <H5 style={{margin: "0px"}}>Ved 100% lønn medberegnet<br/>ferie og ulønnede uker får:</H5>
            <UL>
                <LI>Mor {props.hentAlleMorUker100()} uker med barnet.</LI>
                <LI>Far {props.hentAlleFarUker100()} uker med barnet.</LI>
                <LI>Dere {props.hentDisponibleUker100()} uker til disposisjon.</LI>
            </UL>
            <H5 style={{margin: "0px"}}>Ved 80% lønn medberegnet<br/>ferie og ulønnede uker får:</H5>
            <UL>
                <LI>Mor {props.hentAlleMorUker80()} uker med barnet.</LI>
                <LI>Far {props.hentAlleFarUker80()} uker med barnet.</LI>
                <LI>Dere {props.hentDisponibleUker80()} uker til disposisjon.</LI>
            </UL>
            <br/>
            <H4>Økonomi</H4>
            <H5 style={{margin: "0px"}}>Ved 100% lønn:</H5>
            <UL style={{maxWidth: "350px"}}>
                <LI>Får dere: {props.hentPermisjonsPenger100()} i permisjonspenger etter {(props.perm100.ukerFar + props.perm100.ukerMor)} uker.</LI>
                <LI>Får dere: {props.hentUtbetalt100Etter59()} utbetalt inkludert lønn etter 59 uker dersom dere jobber fra uke 49 til 59.</LI>
            </UL>
            <H5 style={{margin: "0px"}}>Ved 80% lønn:</H5>
            <UL style={{maxWidth: "350px"}}>
                <LI>Får dere: {props.hentPermisjonsPenger80()} i permisjonspenger etter {(props.perm80.ukerFar + props.perm80.ukerMor)} uker.</LI>
                <LI>Får dere: {props.hentUtbetalt100Etter59()} utbetalt inkludert lønn etter 59 uker dersom dere jobber fra uke 49 til 59.</LI>
            </UL>
        </ResultContainer>
    )
    
} 

export default SummaryCombined;