import React from 'react';

const VippsButton = ({onClick, buy}) => {

    if (buy) {
        return (
            <vipps-mobilepay-button
                type="button"
                brand="vipps"
                language="no"
                variant="primary"
                rounded="false"
                verb="pay"
                stretched="true"
                branded="true"
                loading="false"
                onClick={onClick}
            />
        );
    } else {
        return (
            <vipps-mobilepay-button
                type="button"
                brand="vipps"
                language="no"
                variant="primary"
                rounded="false"
                verb="continue"
                stretched="true"
                branded="true"
                loading="false"
                onClick={onClick}
            />
        );
    }
};

export default VippsButton;