import styled from "styled-components"

const RadioCheckboxContainer = styled.div`
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    justify-self: center; 
    align-self: center; 
    min-width: 300px;
    max-width: 550px;
`

export default RadioCheckboxContainer;