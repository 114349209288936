import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import DatePicker from "react-datepicker";
import { H5 } from "../theme/text";
import "react-datepicker/dist/react-datepicker.css";
import { color } from "../theme/standardTheme";


const shadowPulse = keyframes`
  0% {box-shadow: rgb(153, 155, 132) 0px 0px 0px 0px;}
  70% {box-shadow: rgba(153, 155, 132, 0) 0px 0px 0px 10px;}
  100% {box-shadow: rgba(153, 155, 132, 0) 0px 0px 0px 0px;}
`

const DateSelectorWrapper = styled.div`
  display: flex; 
  justify-content: center; 
  align-items: center;
  z-index: 30;
  
`

const AnimationWrapper = styled.div`
  animation: 2s ease 0s infinite normal none ${props => props.isClicked? "none" : shadowPulse};
  border-radius: 5px;
`


const DateSelector = (props) => {

  const [startDate, setStartDate] = useState(props.startDate || new Date());
  const [isClicked, setIsClicked] = useState(props.startDate? true : false);

  useEffect(() => {
    setStartDate(props.startDate || new Date());
    if (props.startDate) setIsClicked(true);
  }, [props.startDate]);

  function handleChange(date){
    setStartDate(date);
    props.onChange(date);
    setIsClicked(true);
  }

  function handleSelect(date){
    setIsClicked(true);
    if (props.onSelect){
      props.onSelect(date);
    }
  }

  return (
    <DateSelectorWrapper>
      <H5 style={{margin: "0px 8px"}}>Termindato: </H5>
      <AnimationWrapper isClicked={isClicked}>
        <DatePicker
          selected={startDate}
          onChange={(date) => handleChange(date)} 
          onSelect={(date) => handleSelect(date)}
        />
      </AnimationWrapper>
    </DateSelectorWrapper>
  );
};

export default DateSelector;