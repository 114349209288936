import styled from "styled-components";
import TopicButton from "./TopicButton";
import { color } from "../../theme/standardTheme";

const ShimmerButton = styled(TopicButton)`
    background: linear-gradient(-45deg, ${color.burgundy} 25%, #f6f6f6 50%, ${color.burgundy} 75%);
    background-size: 400% 100%;
    animation: shimmer 15s infinite linear;

    @keyframes shimmer {

        0% {
            background-position: 0% 0;
        }

        
        92% {
            background-position: 0% 0;
        }

        100% {
            background-position: -300% 0;
        }

    }


`
export default ShimmerButton;