import React, { useEffect, useState } from "react";
import ResultContainer from "../containers/ResultContainer";
import { perm80Grenser, perm100Grenser } from "../utils/permisjonsUtils";
import { H4, H5, LI, UL, P } from "../theme/text";

// TODO: Fikse i funksjoner så det tar høyde for 80 og  nice

const Summary = (props) => {

    return(
        <ResultContainer>
            <H4 style={{fontWeight: "bold"}}>Oppsumering med {props.permType}% lønn</H4>
            <H5>Permisjonsfordeling</H5>
            <UL style={{margin: "6px"}}>
                <LI>Mor får {props.perm.ukerMor} uker permisjon</LI>
                <LI>Far for {props.perm.ukerFar} uker permisjon</LI>
                <LI>Totalt får dere {(props.perm.ukerFar + props.perm.ukerMor)} uker permisjon</LI>
            </UL>
            <H5>Tid med barnet</H5>
            <P style={{margin: "0px"}}>Medberegnet ferie og ulønnede uker får:</P>
            <UL>
                <LI>Mor {props.hentAlleMorUker()} uker med barnet.</LI>
                <LI>Far {props.hentAlleFarUker()} uker med barnet.</LI>
                <LI>Dere {props.hentDisponibleUker()} uker til disposisjon.</LI>
            </UL>
            <H5>Økonomi</H5>
            <UL style={{maxWidth: "350px"}}>
                <LI>Dere får: {props.hentPermisjonsPenger()} i permisjonspenger etter {(props.perm.ukerFar + props.perm.ukerMor)} uker.</LI>
                <LI>Dere får: {props.hentUtbetaltEtter59()} utbetalt inkludert lønn etter 59 uker dersom dere jobber fra uke 49 til 59.</LI>
            </UL>
        </ResultContainer>
    )
    
} 

export default Summary;