import styled from "styled-components";
import { borderRaduis } from "../../theme/standardTheme";

const InputFieldContainer = styled.div`
    display: flex; 
    flex-direction: row;
    align-items: center; 
    justify-content: center;
    min-width: 0;
    max-width: ${props => props.maxWidth? props.maxWidth : "none"};
    width: 95%;
    
`

const InputArea = styled.input`
    height: 25px;
    min-width: 0;
    width: 95%;
    border-radius: ${borderRaduis.small};
    border: none; 
    text-align: center;
    font-size: 17px;
    max-width: ${props => props.maxWidth? props.maxWidth : "none"};
    
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

`

const InputLabel = styled.span`

`

const CoParentValue = styled.span`
    height: 25px;
    text-align: center; 
`

export {
    InputFieldContainer,
    InputLabel,
    InputArea,
    CoParentValue,
}