
const grunnbelopet = 118620;

const perm100Grenser = {
    uker: 49,
    fodselsUker: 3,
    morUker: 15, 
    farUker: 15,
    fellesUker: 16,
    maxMor: 34,
    minMor: 18, 
    maxFar: 31,
    minFar: 15,
  }
  
  const perm80Grenser = {
    uker: 61,
    fodselsUker: 3,
    morUker: 19, 
    farUker: 19,
    fellesUker: 20,
    maxMor: 42,
    minMor: 22, 
    maxFar: 39,
    minFar: 19,
  }

function getUpdatedPerm100(uker, type, perm){
    var updatedPerm = {...perm};
    switch (type) {
        case 'ukerFar':
            if (uker < perm100Grenser.minFar) uker = perm100Grenser.minFar;
            else if (uker > perm100Grenser.maxFar) uker = perm100Grenser.maxFar;
            var ukerMor = perm100Grenser.uker - uker;
            updatedPerm.ukerFar = uker; 
            updatedPerm.ukerMor = ukerMor;
            return updatedPerm;
  
        case 'ukerMor':
            if (uker < perm100Grenser.minMor) uker = perm100Grenser.minMor;
            else if (uker > perm100Grenser.maxMor) uker = perm100Grenser.maxMor;
            var ukerFar = perm100Grenser.uker - uker;
            updatedPerm.ukerFar = ukerFar; 
            updatedPerm.ukerMor = uker;
            return updatedPerm;

        case 'ulonnetPermFar':
            if (uker >= 0) {
                updatedPerm.ulonnetPermFar = uker;
                return updatedPerm;
            }
            return false;

        case 'ulonnetPermMor':
            if (uker >= 0) {
                updatedPerm.ulonnetPermMor = uker;
                return updatedPerm;
            }
            return false;

        case 'ferieMor':
            if (uker >= 0) {
                updatedPerm.ferieMor = uker;
                return updatedPerm;
            }
            return false;

        case 'beggeFerie':
            if (uker >= 0) {
                updatedPerm.beggeFerie = uker;
                return updatedPerm;
            }
            return false;

        case 'ferieFar':
            if (uker >= 0) {
                updatedPerm.ferieFar = uker;
                return updatedPerm;
            }
            return false;

        default:
            return false;
        
    }
}

function getUpdatedPerm80(uker, type, perm){
    var updatedPerm = {...perm};
    switch (type) {
        case 'ukerFar':
          if(uker >= perm80Grenser.minFar && uker <= perm80Grenser.maxFar) {
            var ukerMor = perm80Grenser.uker - uker;
            updatedPerm.ukerFar = uker; 
            updatedPerm.ukerMor = ukerMor;
            return updatedPerm;
          }
          return false;
  
        case 'ukerMor':
          if(uker >= perm80Grenser.minMor && uker <= perm80Grenser.maxMor) {
            var ukerFar = perm80Grenser.uker - uker;
            updatedPerm.ukerFar = ukerFar; 
            updatedPerm.ukerMor = uker;
            return updatedPerm;
          }
          return false;

        case 'ulonnetPermFar':
            if (uker >= 0) {
                updatedPerm.ulonnetPermFar = uker;
                return updatedPerm;
            }
            return false;

        case 'ulonnetPermMor':
            if (uker >= 0) {
                updatedPerm.ulonnetPermMor = uker;
                return updatedPerm;
            }
            return false;

        case 'ferieMor':
            if (uker >= 0) {
                updatedPerm.ferieMor = uker;
                return updatedPerm;
            }
            return false;

        case 'beggeFerie':
            if (uker >= 0) {
                updatedPerm.beggeFerie = uker;
                return updatedPerm;
            }
            return false;

        case 'ferieFar':
            if (uker >= 0) {
                updatedPerm.ferieFar = uker;
                return updatedPerm;
            }
            return false;
        


        default:
            return false;
        
      }
}



export {
    perm100Grenser,
    perm80Grenser,
    grunnbelopet,
    getUpdatedPerm100,
    getUpdatedPerm80
}