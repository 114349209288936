import axios from "axios";
import { appConfig } from "../../appConfig";

const host = appConfig.host;
const objectPath = "user";
const userUrl = `${host}/${objectPath}`;

async function createUser(email, termDate, periods, isPayingSubscriber) {
    try {
        const call = {
            method: "post",
            url: `${userUrl}/`,
            data: {
                email: email,
                termDate: termDate,
                periods: periods,
                isPayingSubscriber: isPayingSubscriber,
            }
        }
        const result = await axios(call);
        if (result.data.success) return true;
        else return false;
    } catch (e) {
        console.log('error:', e);
        return false;
    }
}


async function startLoginSession(email) { 
    try {
        const call = {
            method: "post",
            url: `${userUrl}/login/${email}`,
        }
        const result = await axios(call);
        if (result.data) return result.data;
        else return false;
    } catch (e) {
        console.log('error:', e);
        return false;
    }
}

async function verifyLoginSession(sessionId) {
    try {
        const call = {
            method: "get",
            url: `${userUrl}/verifyLogin/${sessionId}`,
        }
        const result = await axios(call);
        if (result.data.success === true) return result.data;
        else return false;
    } catch (e) {
        console.log('error:', e);
        return false;
    }
}

async function saveUserChanges(email, termDate, periods) {
    try {
        const call = {
            method: "put",
            url: `${userUrl}/${email}`,
            data: {
                termDate: termDate,
                periods: periods,
            }
        }
        const result = await axios(call);
        if (result.data.success) return true;
        else return false;
    } catch (e) {
        console.log('error:', e);
        return false;
    }
}

function sendPdf(email, termDate, periods, sections) {
    console.log('getPdfTest');
    try {
        const call = {
            method: "post",
            url: `${host}/pdf`,
            headers: {
                email: email,
            },
            data: {
                termDate: termDate,
                periods: periods,
                sections: sections,
            }
        }
        axios(call).then((result) => {
            console.log('result:', result);
            return result;
        }).catch((e) => {
            console.log('error:', e);
            return false;
        });
    } catch (e) {
        console.log('error:', e);
        return false;
    }
}

export {
    createUser,
    startLoginSession,
    verifyLoginSession,
    saveUserChanges,
    sendPdf,
};