import React, { useEffect } from "react";
import styled from "styled-components";
import { H4, H5, P } from "../theme/text";

const CheckboxWrapper = styled.div`
    display: flex; 
    flex-direction: ${props => props.direction? props.direction : "column"}; 
    justify-content: center; 
    align-items: center: 

    H5 {
        text-align: center;
    }

`

const CheckboxFrame = styled.div`
    height: 18px; 
    width: 18px; 
    margin: 8px; 
    background-color: white;
    align-self: center; 
    justify-self: center; 
    border-radius: 50%;
    display: flex; 
    justify-content: center; 
    align-items: center; 
`
const CheckMark = styled.div`
    height: 10px; 
    width: 10px; 
    background-color: ${props => props.color? props.color : "black"}; 
    border-radius: 5px;
`

const Checkbox = (props) => {

    useEffect(() => {

    }, [props.checked])
    
    return(
        <CheckboxWrapper direction={props.direction}>
            <H5>{props.label}</H5>
            <CheckboxFrame onClick={props.onClick}>
                {props.checked? <CheckMark color={props.color}></CheckMark>:<></>}
            </CheckboxFrame>
        </CheckboxWrapper>
    )
}

export default Checkbox;