
import { color } from "chart.js/helpers";
import { perm100Grenser, perm80Grenser } from "./permisjonsUtils";

const permSectionColors = {
    fodsel: "#B3C5A9",
    mamma: "#FFBCBD",
    pappa: "#A6C3DC",
    felles: "#C095CF",
    mammaFerie: "#FFBDD2",
    pappaFerie: "#718DA5",
    beggeFerie: "#B88AA2",
    mammaUlonnetPerm: "#FF859B",
    pappaUlonnetPerm: "#7A8BC0",
}

function getPermWeeks(permType, model100) {
    switch (permType){
        case 'mamma':
            if (model100) return perm100Grenser.morUker
            else return perm80Grenser.morUker
        
        case 'pappa':
            if (model100) return perm100Grenser.farUker;
            else return perm80Grenser.farUker;

        case 'felles':
            if (model100) return perm100Grenser.fellesUker;
            else return perm80Grenser.fellesUker
            
        case 'mammaFerie':
            return 5; 

        case 'pappaFerie':
            return 5;

        case 'beggeFerie':
            return 5;
        
        case 'mammaUlonnetPerm':
            return 2;

        case 'pappaUlonnetPerm':
            return 2;

        default:
            return '';
    }
}

function getPermColor(type) {
    switch (type) {
        case ('fodsel'):
            return(permSectionColors.fodsel);

        case ('mamma'):
            return(permSectionColors.mamma); 

        case ('pappa'):
            return(permSectionColors.pappa);

        case ('felles'):
            return(permSectionColors.felles);

        case ('mammaFerie'):
            return(permSectionColors.mammaFerie);
            
        case ('pappaFerie'):
            return(permSectionColors.pappaFerie);

        case ('beggeFerie'):
            return(permSectionColors.beggeFerie);

        case ('mammaUlonnetPerm'):
            return(permSectionColors.mammaUlonnetPerm);
        
        case ('pappaUlonnetPerm'):
            return(permSectionColors.pappaUlonnetPerm);

        default:
            return ("red");
        
        
    }
}

function getPeriodDescription(type) {
    switch (type) {
        case ('fodsel'):
            return(
                "Fødselspermisjonen! En spennende peiode for hele familien. Disse tre ukene er til mor slik at hun kan slappe og forberede seg til fødselen."
            );

        case ('mamma'):
            return(
                "Mammaspermisjon! En tid satt av kun til mor, bruk denne perioden til å lære barne ditt å kjenne, alle er forskjelloge og har ulike behov."
            ); 

        case ('pappa'):
            return(
                "Pappapermisjon! Endelig er tiden her, far sin tur til å bli ekstra godt kjennt med barnet, nyt tiden!"
            );

        case ('felles'):
            return(
                "Fordelbar permisjon! Dette er uker dere kan fordele fritt mellom de to foreldrene og kan tilpasse etter deres og barnets behov."
            );

        case ('mammaFerie'):
            return(
                "Mammas Ferie! Husk at det a bruke feire er veldig nyttig for å få tiden til å strekke helt til dere får barnehageplass."
            );
            
        case ('pappaFerie'):
            return(
                "Pappas Ferie! Husk at det a bruke feire er veldig nyttig for å få tiden til å strekke helt til dere får barnehageplass. "
            );

        case ('beggeFerie'):
            return(
                "Ferie Sammen! Dette er perioden dere endelig har masse fritid sammen. Bruk den godt sammen med deres nye familiemedlem."
            );

        case ('mammaUlonnetPerm'):
            return(
                "Ulønnet Permisjon, dette er veldig praktisk for å strekke tiden til man begynner i barnehage. Husk å snakke med arbeidsgiver."
            );
        
        case ('pappaUlonnetPerm'):
            return(
                "Ulønnet Permisjon, dette er veldig praktisk for å strekke tiden til man begynner i barnehage. Husk å snakke med arbeidsgiver."
            );

        default:
            return (null);   
    }
}

const monthData = [
    {name: "Januar", numberOfDays: 31, number: 0},
    {name: "Februar", numberOfDays: 28, number: 1},
    {name: "Mars", numberOfDays: 31, number: 2},
    {name: "April", numberOfDays: 30, number: 3},
    {name: "Mai", numberOfDays: 31, number: 4},
    {name: "Juni", numberOfDays: 30, number: 5},
    {name: "Juli", numberOfDays: 31, number: 6},
    {name: "August", numberOfDays: 31, number: 7},
    {name: "September", numberOfDays: 30, number: 8},
    {name: "Oktober", numberOfDays: 31, number: 9},
    {name: "November", numberOfDays: 30, number: 10},
    {name: "Desember", numberOfDays: 31, number: 11}
];

function getStartOfMonth(date){
    var y = date.getFullYear();
    var m = date.getMonth();
    return new Date(y, m, 1, 13);
}

function getEndOfMonth(date){
    var y = date.getFullYear();
    var m = (date.getMonth() + 1);
    return new Date(y, m, 0, 13);
}

function shiftDate(date, shift) {
    var d = new Date(date);
    d.setDate(d.getDate() + 7 * shift);
    d.setDate(d.getDate()-1);
    return d;
}

function yearDiff(d1, d2) {
    var y1 = d1.getFullYear();
    var y2 = d2.getFullYear();
    return (y2-y1);
}

function getMonthDiff(d1, d2) {
    var months;
    months = yearDiff(d1, d2) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months;
}

function getMonthsBetween(d1, d2) {
    var between; 
    var diff = getMonthDiff(d1, d2);
    if (diff > 0) {
        between = diff - 1;
        return (between);
    } else return 0;
}

function isSameMonth(d1, d2) {
    var md = getMonthDiff(d1, d2);
    if (md === 0) return true;
    else return false;
}

function exactSameDate(d1, d2) {
    if (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
    ) return true;
    else return false;
}

function getNextDate(period) {
    var delta = 1; 
    if (period.type === 'fodsel') delta++;
    var nextDate = new Date(period.endDate.getFullYear(), period.endDate.getMonth(), period.endDate.getDate()+delta);
    return (nextDate);
}

function getReadableDate(date){
    var d = new Date(date);
    var readableUS = d.toDateString();
    var splitDate = readableUS.split(' ');
    var readableNO = `${splitDate[2]} ${splitDate[1]}, ${splitDate[3]}`;
    return readableNO;
}

function buildPeriod(id, name, start, duration, type){
    var periodName = `${name} - ${duration} uker`;
    var endDate = shiftDate(start, duration);
    return (
        {
            id: id, 
            name: periodName, 
            shortName: name,
            startDate: start, 
            endDate: endDate,
            readableStart: getReadableDate(start),
            readableEnd: getReadableDate(endDate),
            duration: duration, 
            type: type,
            color: getPermColor(type),
            description: getPeriodDescription(type)
        }
    )
}

function buildSection(period, start, end, sectionType=false){
    var section = {...period};
    section.startDate = new Date(start);
    section.endDate = new Date(end);
    section.radiusLeft = true;
    section.radiusRight = true;

    if (sectionType === 'first'){
        section.radiusLeft = true;
        section.radiusRight = false;
    } else if (sectionType === 'mid'){
        section.radiusLeft = false;
        section.radiusRight = false;
    } else if (sectionType === 'last'){
        section.radiusLeft = false;
        section.radiusRight = true;
    }
    return ( section );
}

function getFirstSection(period){
    var start = new Date(period.startDate);
    var end = getEndOfMonth(period.startDate);
    var section = buildSection(period, start, end, 'first');
    return section;
}

function getLastSection(period) {
    var start = getStartOfMonth(period.endDate);
    var end = new Date(period.endDate);
    var section = buildSection(period, start, end, 'last');
    return section;
}

function getMidSection(period, date) {
    var start = getStartOfMonth(date);
    var end = getEndOfMonth(date);
    var section = buildSection(period, start, end, 'mid');
    return section;
}

function getPermSections(period){
    var sections = [];
    if (isSameMonth(period.startDate, period.endDate)) {
        var section = buildSection(period, period.startDate, period.endDate);
        sections.push(section);
    } else {
        var firstSection = getFirstSection(period);
        sections.push(firstSection);
        
        var monthsBetween = getMonthsBetween(period.startDate, period.endDate);
        if (monthsBetween > 0){
            var midDate = new Date(period.startDate.getFullYear(), period.startDate.getMonth()+1, 15, 13);
            for (let i=0; i<monthsBetween; i++){
                var midSection = getMidSection(period, midDate);
                sections.push(midSection);
                midDate = new Date(midDate.getFullYear(), midDate.getMonth()+1, 15, 13);
            }
        }

        var lastSection = getLastSection(period);
        sections.push(lastSection);
    }
    return(sections);
}

export {
    permSectionColors,
    monthData,
    getPermSections,
    shiftDate,
    getPermColor,
    buildPeriod,
    exactSameDate,
    getNextDate,
    getMonthDiff,
    getPermWeeks
}