import React from 'react';
import axios from 'axios';
import { appConfig } from '../../appConfig';

const host = appConfig.host;

const VippsButton = ({product}) => {

    const buttonOnClick = async () => {
        const call = {
            method: "post",
            url: `${host}/vipps/pay`,
            data: {
                product: product
            }
        }
        const response = await axios(call);
        console.log(response);
    }

    return ( 
        <vipps-mobilepay-button
            type="button"
            brand="vipps"
            language="no"
            variant="primary"
            rounded="false"
            verb="pay"
            stretched="false"
            branded="true"
            loading="false"
            onClick={buttonOnClick}
        />
    );
};

export default VippsButton;