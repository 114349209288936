import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import {H1, H3, P, UL, LI } from "../theme/text";
import { borderRaduis, color, font } from "../theme/standardTheme";
import Checkbox from "./Checkbox";
import VippsButton from "./Buttons/VippsButton";
import { paymentTypes } from "../server/api/payments";



const StyledProduct = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: ${color.tertiary};
    width: 100%;
    max-width: 350px;
    padding: 40px;
    padding-bottom: 40px;
    border-radius: ${borderRaduis.medium};
    height: 100%;
    margin-top: 20px;

    UL {
        margin: 0px;
    }

    @media (max-width: 750px) {
        margin-top: 5px;
        padding: 10px 15px;

        H1 {
            font-size: 28px;
        }
        H3 {
            font-size: 16px;
            margin-bottom: 5px;
        }
        LI {
            font-size: 12px;
        }

    }

`;

const PriceTag = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: end;
    margin: 20px 0px 10px 0px;

    H1 {
        margin: 0px;
        padding: 0px;
    }

    P {
        margin: 0px;
        padding: 0px;
        margin-bottom: 6px;
        margin-left: 8px;

        @media (max-width: 750px) {
            font-size: 13px;
            margin-bottom: 0px;
        }
    }
        
`

const PurchaseButton = styled.button`
    width: 100%;
    height: 40px;
    border: none;
    border-radius: ${borderRaduis.small};
    background-color: ${color.burgundy};
    font-family: ${font};
    font-size: 14px;
    color: ${color.fontSecondary};
    font-weight: bold;
    opacity: 0.9;

    &: hover {
        opacity: 1;
        scale: 1.02;

    }
`

const shadowPulse = keyframes`
  0% {box-shadow: rgb(153, 155, 132) 0px 0px 0px 0px;}
  70% {box-shadow: rgba(153, 155, 132, 0) 0px 0px 0px 5px;}
  100% {box-shadow: rgba(153, 155, 132, 0) 0px 0px 0px 0px;}
`

const AnimationWrapper = styled.div`
  animation: 3s ease 0s infinite normal none ${props => props.animate? shadowPulse : "none"};
  border-radius: 5px;
`

const CheckboxWrapper = styled.div`
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    min-height: 40px;

`

const CheckboxFrame = styled.div`
    height: 18px; 
    width: 18px; 
    margin: 8px; 
    background-color: white;
    border-radius: 50%;
    display: flex; 
    justify-content: center; 
    align-items: center; 
`
const CheckMark = styled.div`
    height: 10px; 
    width: 10px; 
    background-color: ${props => props.color? props.color : "black"}; 
    border-radius: 5px;
`

const PriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: auto;
    gap: 10px;
    
`

const Product = (props) => {
    
    const [product, setProduct] = useState(props.product || {});
    const [features, setFeatures] = useState(props.product?.features || ['Feature 1', 'Feature 2', 'Feature 3']);
    const [useProductVariation, setUseProductVariation] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        setProduct(props.product || {});
        setFeatures(props.product?.features || ['Feature 1', 'Feature 2', 'Feature 3']);
    }, [props.product]); 

    const switchProduct = () => {
        if (useProductVariation){
            setProduct(props.product || {});
            setFeatures(props.product?.features || ['Feature 1', 'Feature 2', 'Feature 3']);
        } else {
            setProduct(props.productVariation || {});
            setFeatures(props.productVariation?.features || ['Feature 1', 'Feature 2', 'Feature 3']);
        }
        setUseProductVariation(!useProductVariation);
    }

    const handleProductCardClick = (event) => {
        event.stopPropagation();
        if (props.onClick) props.onClick();
    }
    
    return(
        <StyledProduct onClick={ ( event ) => handleProductCardClick(event)}>
            <H3 style={{marginTop: '0px'}} >{product.name || 'Produkt'}</H3>
            <P>{product.description || 'Kort beskrivelse av produktet'}</P>
            <PriceWrapper>
                <PriceTag>
                    <H1>kr. {product.price || '200'}</H1>
                    <P>{product.paymentFrequency || 'en gang'}</P>
                </PriceTag>
                {/*
                {props.productVariation? 
                    <CheckboxWrapper>
                        <P style={{margin: '0px 0px 0px 0px'}}>{useProductVariation? `Batal månedlig` : `Betal årlig og spar 35%`}</P>
                        <CheckboxFrame onClick={switchProduct}>
                            {useProductVariation? <CheckMark color={color.burgundy}></CheckMark>:<></>}
                        </CheckboxFrame>
                    </CheckboxWrapper>
                : <CheckboxWrapper empty={true} />}
                */}
                <AnimationWrapper animate={props.animate}>
                    <PurchaseButton onClick={() => props.purchase(product)}>{props.btnText || 'Kjøp'}</PurchaseButton>
                </AnimationWrapper>

                <VippsButton buy={product.type === 'ONE_TIME'} onClick={() => props.purchase(product, paymentTypes.VIPPS)} />
                {/*
                {screenWidth > 750?<>
                    <P style={{margin: '10px 0px 0px 0px'}}>Dette inkluderer</P>
                    <UL>
                        {features.map((feature, ix) => {
                            return(
                                <LI key={ix}>{feature}</LI>
                            )
                        })}
                    </UL>
                </>: null}
                */}
            </PriceWrapper>
        </StyledProduct>
    );
};

export default Product;