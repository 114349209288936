import React, { useEffect, useState } from "react";
import PlaningViewContainer from "../containers/PlaningViewContainer";
import PlaningEditorContainer from "../containers/PeriodEditorContainer";
import Calendar from "../components/Calendar/Calendar";
import RadioCheckboxContainer from "../components/PlanningInput/RadioCheckboxContainer";
import Checkbox from "../components/Checkbox";
import { H4, H5, P } from "../theme/text";
import DateSelector from "../components/DateSelector";
import { monthData, getPermSections, buildPeriod, shiftDate, getNextDate, getPermColor } from "../utils/permSectionUtils";
import AddSectionTool from "../components/AddSectionTool";
import Period from "../components/Period";
import { perm100Grenser, perm80Grenser } from "../utils/permisjonsUtils";
import Modal from "../containers/Modal";
import InnerModalPermSections from "../components/InnerModalPermSections";
import TopicButton from "../components/Buttons/TopicButton";
import InnerModalWantPdf from "../utils/pdf/InnerModalWantPdf";
import html2canvas from "html2canvas";
import Cookie from 'universal-cookie';
import { getProducts } from "../server/api/payments";
import { checkStripeSessionStatus, checkVippsSessionStatus } from "../server/api/payments";
import { createUser, verifyLoginSession, saveUserChanges, sendPdf} from "../server/api/user";
import LoginModal from "../components/LoginModal";
import PaymentConfirmationMessageModal from "../components/PaymentComfirmationMessageModal";
import useMixpanel from "../hooks/useMixpanel";
import ShimmerButton from "../components/Buttons/ShimmerButton";


const cookies = new Cookie();

const PlanningView = (props) => { 


    var previousYear; 
    var permYear;
    var nextYear;
    var nextNextYear;

    const mixpanel = useMixpanel();

    const [months, setMonths] = useState(new Array(18).fill({}));
    const [model100, setModel100] = useState(true);
    const [termDate, setTermDate] = useState(checkTermDateCookie() || '');
    const [sections, setSections] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [showPlannerInfo, setShowPlannerInfo] = useState(false);
    const [wantPdf, setWantPdf] = useState(false);
    const [products, setProducts] = useState([]);
    const [paymentConfirmed, setPaymentConfirmed] = useState(null);
    const [signedIn, setSignedIn] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [cookieBuildComplete, setCookieBuildComplete] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    useEffect(() => {
        if (!checkTermDateCookie()) updateMonthOrder();
        buildFromCookies();
        fetchProducts();
        fetchPaymentStatus();
        checkLoginSession();
    }, [])

    useEffect(() => {
        if (
            cookieBuildComplete === true &&
            paymentConfirmed === true &&
            emailSent === false &&
            periods.length > 0 &&
            sections.length > 0
        ) {
            sendPdf(userEmail, termDate, periods, sections);
            setEmailSent(true);
        }

    }, [cookieBuildComplete, paymentConfirmed]);

    useEffect(() => {
        if ((signedIn === true || signedIn === 'true') && userEmail) {
            storeCookies();
            saveUserChanges(userEmail, termDate, periods);
        }
    }, [termDate, periods]);

    function sendPdfHandler() {
        sendPdf(userEmail, termDate, periods, sections);
        setEmailSent(true);
    }


    const fetchPaymentStatus = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const stripeSessionId = urlParams.get('stripe_session_id');
        const vippsSessionId = urlParams.get('vipps_session_id');
        const gotSubscription = urlParams.get('subscription');
 
        var paymentStatus = null;
        if (stripeSessionId) {
            mixpanelTrackEvent('Payment', {type: 'stripe'});
            var stripeStatus = await checkStripeSessionStatus(stripeSessionId);
            if (stripeStatus === 'complete') paymentStatus = 'COMPLETE';
            else paymentStatus = 'FAILED';
        } else if (vippsSessionId) {
            mixpanelTrackEvent('Payment', {type: 'vipps'});
            var vippsStatus = await checkVippsSessionStatus(vippsSessionId, gotSubscription);
            if (vippsStatus?.state === 'AUTHORIZED' && vippsStatus?.capturedAmount > 0) paymentStatus = 'COMPLETE';
            else if (vippsStatus?.state === 'OLD_PAYMENT') paymentStatus = 'COMPLETE_X2';
            else paymentStatus = 'FAILED';
        }

        if (paymentStatus === 'COMPLETE' || paymentStatus === 'COMPLETE_X2') {
            setPaymentConfirmed(true);
            if (gotSubscription==='true') {
                mixpanelTrackEvent('Purchase', {type: 'subscription'});
                setSignedIn(true)
                createUser(cookies.get('userEmail'), cookies.get('termDate'), cookies.get('periods'), true);
            } else {
                createUser(cookies.get('userEmail'), cookies.get('termDate'), cookies.get('periods'), false);
                mixpanelTrackEvent('Purchase', {type: 'pdf'});
            }
        } else if (paymentStatus === 'FAILED') {
            setPaymentConfirmed(false);
            emptyCookies(); 
        }
        if (gotSubscription !== 'true') emptyCookies();
    }

    function  terminatePurchaseProcess() {
        setWantPdf(false);
        if(!signedIn) emptyCookies();
    }

    async function checkLoginSession(){
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const subscriptionParam = urlParams.get('subscription');
        const stripe_session_id_param = urlParams.get('stripe_session_id');
        if (!subscriptionParam && !stripe_session_id_param && userEmail) {
            setSignedIn(true);
            buildFromCookies();
        }
    }


    function storeCookies(cakeName=false, cake=false, preventDefault=false){
        if (cake && cakeName) cookies.set(cakeName, cake, {path: '/'});

        if (preventDefault) return;
        cookies.set('periods', periods, {path: '/'});
        cookies.set('termDate', termDate, {path: '/'});
    }

    function emptyCookies(){
        cookies.remove('userEmail', { path: '/' });
        cookies.remove('periods', { path: '/' });
        cookies.remove('termDate', { path: '/' });
    }

    const fetchProducts = async () => {
        const p = await getProducts();
        if (p) setProducts(p);
    }

    function checkTermDateCookie(){
        var td = cookies.get('termDate');
        if (td){
            var date = new Date(td);
            return date;
        } else return false;
    }

    function buildFromCookies(){
        var email = cookies.get('userEmail');
        if (email) {
            setUserEmail(email)
        };
        var cakes = cookies.get('periods');
        if (!cakes?.length) {
            mixpanelTrackEvent('Visit');
            return false
        };
        var parsedCookie = [];
        for (let i=0; i<cakes.length; i++){
            let cake = cakes[i];
            cake.startDate = new Date(cake.startDate);
            cake.endDate = new Date(cake.endDate);
            parsedCookie.push(cake);
        }
        var td = new Date(cookies.get('termDate'))
        setTermDate(td);
        setPeriods(parsedCookie);
        refreshSections(parsedCookie);

        previousYear = td.getUTCFullYear()-1;
        permYear = td.getUTCFullYear();
        nextYear = td.getUTCFullYear()+1;
        nextNextYear = td.getUTCFullYear()+2;
        updateMonthOrder(parsedCookie[0].startDate.getMonth());
        setCookieBuildComplete(true);
    }

    function updateMonthOrder(startAt = 0) {
        if (startAt < 0 || 11 < startAt) startAt = 1;
        var mtnNum = startAt; 
        var mnts=[];
        var nextYearCount = 0;
        for (let i=0; i<20; i++) {
            
            var mnt = {
                number: i,
                name: monthData[mtnNum].name,
                numberOfDays: monthData[mtnNum].numberOfDays,
                calendarNumber: monthData[mtnNum].number,
            }
            
            if (mnt.calendarNumber===12 && mnt.number===0) {
                mnt.year = previousYear; 
            }
            else if(mnt.calendarNumber >= mnt.number) {
                mnt.year = permYear;
            } else {
                nextYearCount++;
                if (nextYearCount > 12) {
                    mnt.year = nextNextYear;
                } else {
                    mnt.year = nextYear;
                }
            }
            
            
            mnts.push(mnt);
            mtnNum++;
            if (mtnNum > 11) mtnNum=0;
        }
        setMonths(mnts)
    }

    function getPeriodId(){
        var rand = Math.random()*100000;
        var id = Math.round(rand);
        return id;
    }

    function mixpanelTrackEvent(eventName, data={}){
        mixpanel.trackEvent(eventName, { ...data, numPeriods: periods.length, termDate: termDate });
    }

    function termDateChange(date) {
        mixpanelTrackEvent('TermDate');
        // termdate
        setTermDate(date);
        // periods
        var id = getPeriodId();
        var fodselStartDate = shiftDate(date, -3);
        fodselStartDate.setDate(fodselStartDate.getDate()+1);
        var fodsel = buildPeriod(id, 'Fødselsperm', fodselStartDate, 3, 'fodsel');
        var dur; 
        if (model100) dur = perm100Grenser.morUker
        else dur = perm80Grenser.morUker
        var mamma = buildPeriod(getPeriodId(), 'Mammaperm', getNextDate(fodsel), dur, 'mamma');
        if (model100) dur = perm100Grenser.fellesUker
        else dur = perm80Grenser.fellesUker
        var felles = buildPeriod(getPeriodId(), 'Fellesperm', getNextDate(mamma), dur, 'felles');
        if (model100) dur = perm100Grenser.farUker
        else dur = perm80Grenser.farUker
        var pappa = buildPeriod(getPeriodId(), 'Pappaperm', getNextDate(felles), dur, 'pappa');
        var initialPeriods = [fodsel, mamma, felles, pappa];
        setPeriods(initialPeriods);
        refreshSections(initialPeriods);

        previousYear = date.getUTCFullYear()-1;
        permYear = date.getUTCFullYear();
        nextYear = date.getUTCFullYear()+1;
        nextNextYear = date.getUTCFullYear()+2;
        updateMonthOrder(fodsel.startDate.getMonth());
    }

    function addSection(duration, label, value) {
        var id = getPeriodId();
        var startDate = getNextDate(periods[periods.length-1]);

        var period = buildPeriod(id, label, startDate, duration, value);
        var updatedPeriods = [...periods];
        updatedPeriods.push(period);
        setPeriods(updatedPeriods);

        var newSections = getPermSections(period);
        var updatedSections = [...sections];
        updatedSections.push(...newSections);
        setSections(updatedSections);
    }
    
    function shufflePeriod(direction, periodId){
        if (periods.length<3) return;
        var before = [...periods];
        var switchThem;
        var after;
        var theSwitch = [];
        var switchNumber = before.map(function(e) { return e.id; }).indexOf(periodId);
        if (switchNumber == 0) return; 
        if (direction === 'up') {
            if (switchNumber < 2) return;
            switchThem = before.splice(switchNumber-1);
            after = switchThem.splice(2)
        } else if (direction === 'down'){
            if (switchNumber > periods.length-2) return; 
            switchThem = before.splice(switchNumber);
            after = switchThem.splice(2);
        } else return; 
        mixpanelTrackEvent('Shuffle Period');
        var first = switchThem[0];
        var second = switchThem[1];
        var firstPeriod = buildPeriod(second.id, second.shortName, first.startDate, second.duration, second.type);
        var secondStartDate = getNextDate(firstPeriod);
        var secondPeriod = buildPeriod(first.id, first.shortName, secondStartDate, first.duration, first.type);
        theSwitch.push(firstPeriod);
        theSwitch.push(secondPeriod);

        var updatedPeriods = [];
        updatedPeriods.push(...before);
        updatedPeriods.push(...theSwitch);
        updatedPeriods.push(...after);
        setPeriods(updatedPeriods);
        refreshSections(updatedPeriods);
    }

    function deletePeriod(periodId) {
        if (periods.length<2) return; 
        mixpanelTrackEvent('Delete Period');
        var updatedPeriods = [periods[0]];
        for (let i=1; i<periods.length; i++){
            var per = periods[i];
            if (per.id !== periodId){
                var startDate = getNextDate(updatedPeriods[updatedPeriods.length-1]);
                var period = buildPeriod(per.id, per.shortName, startDate, per.duration, per.type);
                updatedPeriods.push(period);
            }
        }
        setPeriods(updatedPeriods);
        refreshSections(updatedPeriods);
    }

    function refreshSections(updatedPeriods){
        var updatedSections = [];
        for (let per of updatedPeriods){
            var newSections = getPermSections(per);
            updatedSections.push(...newSections);
        }
        setSections(updatedSections);
    }

    function switchTo80(){

        mixpanelTrackEvent('Set 80%');
        setModel100(false);
        if (!periods.length) return; 
        var oldPeriods = [...periods];
        var updatedPeriods = [periods[0]];
        for (let i = 0; i<oldPeriods.length; i++){
            var p = oldPeriods[i];
            if (p.type === 'fodsel') continue;
            else {
                var startDate = getNextDate(updatedPeriods[updatedPeriods.length-1]);
                var period;
                if (p.type === 'mamma' && p.duration === perm100Grenser.morUker){
                    period = buildPeriod(p.id, p.shortName, startDate, perm80Grenser.morUker, p.type);
                } else if (p.type === 'pappa' && p.duration === perm100Grenser.farUker) {
                    period = buildPeriod(p.id, p.shortName, startDate, perm80Grenser.farUker, p.type);
                } else if (p.type === 'felles' && p.duration === perm100Grenser.fellesUker){
                    period = buildPeriod(p.id, p.shortName, startDate, perm80Grenser.fellesUker, p.type);
                } else {
                    period = buildPeriod(p.id, p.shortName, startDate, p.duration, p.type);
                }
                updatedPeriods.push(period);
            } 
        }
        setPeriods(updatedPeriods);
        refreshSections(updatedPeriods);

    }

    function switchTo100(){
        setModel100(true);
        mixpanelTrackEvent('Set 100%');
        if (!periods.length) return; 
        var oldPeriods = [...periods];
        var updatedPeriods = [periods[0]];
        for (let i = 0; i<oldPeriods.length; i++){
            var p = oldPeriods[i];
            if (p.type === 'fodsel') continue;
            else {
                var startDate = getNextDate(updatedPeriods[updatedPeriods.length-1]);
                var period;
                if (p.type === 'mamma' && p.duration === perm80Grenser.morUker){
                    period = buildPeriod(p.id, p.shortName, startDate, perm100Grenser.morUker, p.type);
                } else if (p.type === 'pappa' && p.duration === perm80Grenser.farUker) {
                    period = buildPeriod(p.id, p.shortName, startDate, perm100Grenser.farUker, p.type);
                } else if (p.type === 'felles' && p.duration === perm80Grenser.fellesUker){
                    period = buildPeriod(p.id, p.shortName, startDate, perm100Grenser.fellesUker, p.type);
                } else {
                    period = buildPeriod(p.id, p.shortName, startDate, p.duration, p.type);
                }
                updatedPeriods.push(period);
            } 
        }
        setPeriods(updatedPeriods);
        refreshSections(updatedPeriods);
    }

    function editPeriod(periodId, newDuration, newType, newName) {
        mixpanelTrackEvent('Edit Period');
        var oldPeriods = [...periods];
        var updatedPeriods = [periods[0]];
        for (let i = 0; i<oldPeriods.length; i++){
            var per = oldPeriods[i];
            if (per.type === 'fodsel') continue;
            else if (per.id != periodId){
                var startDate = getNextDate(updatedPeriods[updatedPeriods.length-1]);
                var period = buildPeriod(per.id, per.shortName, startDate, per.duration, per.type);
                updatedPeriods.push(period);
            } else {
                var startDate = getNextDate(updatedPeriods[updatedPeriods.length-1]);
                var period = buildPeriod(per.id, newName, startDate, newDuration, newType);
                updatedPeriods.push(period);
            }
        }
        setPeriods(updatedPeriods);
        refreshSections(updatedPeriods);
    }

    

    async function getPeriodImage(){ // not working properly i think
        const element = document.getElementById('pdfPeriods');
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL();
        const Base64ImageString = data.substring(data.indexOf(',')+1);

        var link = document.createElement('a');
        link.href = data;
        link.download = 'downloaded-image.jpg';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return Base64ImageString;
    }

    const login = async (userData) => {
        setShowLogin(false);
        setSignedIn(true);
        var p = JSON.parse(userData.periods);
        storeCookies('userEmail', userData.email, true);
        storeCookies('termDate', userData.termDateText, true);
        storeCookies('periods', p, true);
        buildFromCookies();
    }

    const logout = () => {
        emptyCookies();
        setSignedIn(false);
        setPeriods([]);
        setTermDate('');
        setSections([]);
    }

    const showWantPdfHandler = (state) => {
        setWantPdf(state);
        mixpanelTrackEvent('Save');
    }
    

    return(<>
        <PlaningViewContainer>
            <PlaningEditorContainer  color={props.themeColor.secondary}>
                {signedIn? 
                    <div style={{display: 'flex', gap: '10px', marginBottom: '20px', width: '100%', justifyContent: 'start'}}>
                        <TopicButton onClick={logout}>Logg ut</TopicButton>
                    </div>
                :   
                    <div style={{display: 'flex', gap: '10px', marginBottom: '20px', width: '100%', justifyContent: 'start'}}>
                        <P>Har du bruker allrede?</P>
                        <TopicButton onClick={() => setShowLogin(true)}>Logg inn</TopicButton>
                    </div>
                }
                <H4 style={{marginBottom: "16px", textAlign: 'center'}}>Start med å sette en termindato og tilpass din permisjonstid!</H4>
                <DateSelector
                    onChange={termDateChange}
                    startDate={termDate}
                />
                {(termDate==='')?<></>:
                    <>
                        <AddSectionTool
                            color={(termDate==='')?{tertiary: "#d3d3d3"}:props.themeColor}
                            updateMonths={updateMonthOrder}
                            sections = {sections}
                            setSections={setSections}
                            periods={periods}
                            setPeriods={setPeriods}
                            getPeriodId={getPeriodId}
                            addSection={addSection}
                            model100={model100}
                            setShowPlannerInfo={setShowPlannerInfo}
                            mixpanelTrackEvent={mixpanelTrackEvent}
                        />
                        <RadioCheckboxContainer>
                            <H5>Velg en utbetalingsmodel: </H5>
                            <Checkbox color={props.themeColor.tertiary} label={"100%"} checked={(model100===true)} onClick={switchTo100}/>
                            <Checkbox color={props.themeColor.tertiary} label={"80%"} checked={(model100===false)} onClick={switchTo80}/>
                        </RadioCheckboxContainer>
                        <H4 style={{marginTop: '10px', marginBottom: '0px'}}>{'🩶 Få planen på pdf 🩶'}</H4>
                        <ShimmerButton style={{width: "240px", height: '40px', margin: "10px", marginBottom: '20px'}} onClick={() => {showWantPdfHandler(true)}}>Abonner og lagre</ShimmerButton>
                    </>}
                    {periods.length? <>
                        {periods.map(period => {
                            var color = getPermColor(period.type);
                            return(
                                <Period
                                    key={period.id}
                                    period={period}
                                    shufflePeriod={shufflePeriod}
                                    deletePeriod={deletePeriod}
                                    color={color}
                                    editPeriod={editPeriod}
                                />
                            )
                        })}
                    </>: <></>}
            </PlaningEditorContainer>
            <Calendar
                months = {months}
                termDate = {termDate}
                sections = {sections}
            />
        </PlaningViewContainer>
        <Modal show={showPlannerInfo} onClick={() => setShowPlannerInfo(false)}>
            <InnerModalPermSections/>
        </Modal>
        <Modal show={wantPdf} onClick={terminatePurchaseProcess}>
            <InnerModalWantPdf
                show={wantPdf}
                periods={periods}
                sections={sections}
                termDate={termDate}
                getPeriodImage={getPeriodImage}
                close = {terminatePurchaseProcess}
                products={products}
                storeCookies={storeCookies}
                mixpanelTrackEvent = {mixpanelTrackEvent}
                userEmail = {userEmail}
                signedIn = {signedIn}
                sendPdfHandler = {sendPdfHandler}
            ></InnerModalWantPdf>
        </Modal>
        <LoginModal show={showLogin} close={() => setShowLogin(false)} loadLogin={login}></LoginModal>
        <PaymentConfirmationMessageModal show={(paymentConfirmed !== null)} close={() => setPaymentConfirmed(null)} success={paymentConfirmed}/>
    </>);
}

export default PlanningView;