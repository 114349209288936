import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { appConfig } from '../appConfig';

const useMixpanel = () => {
    useEffect(() => {
        // Initialize Mixpanel
        mixpanel.init('6f1b0036320ef13e713c91dae14506f7');

        // Clean up on component unmount
        return () => {
            // Reset Mixpanel user
            mixpanel.reset();
        };
    }, []);

    const trackEvent = (eventName, properties) => {
        // Track custom event
        if (appConfig.env === 'dev') {
            console.log('Mixpanel Event stopped in development mode.');
            console.log('Event name:', eventName);
            console.log('Event Properties:', properties);
        } else mixpanel.track(eventName, properties);
    };

    return { trackEvent };
};

export default useMixpanel;