import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../containers/Modal';
import InnerModalContainer from '../containers/InnerModalContainer';
import { H2, P } from '../theme/text';
import InputField from '../components/Fields/InputField';
import TopicButton from './Buttons/TopicButton';
import { color } from '../theme/standardTheme';
import { startLoginSession } from '../server/api/user';

const CloseButton = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    display: inline-block;
    padding: 8px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: ${color.fontSecondary};
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
`

const PaymentConfirmationMessageModal = (props) => {

    return (
        <Modal show={props.show} onClick={props.close}>
            <InnerModalContainer style={{maxWidth: '600px'}} onClick={(event) => event.stopPropagation()}>
                {props.success === true? <>
                    <H2>{'Velkommen til Foreldrepenger.no 🩶'}</H2>
                    <P style={{fontSize: '14px', fontWeight: 'bold'}}>{"Din betaling var vellykket :-)"}</P>
                    <P style={{fontSize: '14px', fontWeight: 'bold'}}>Takk for at du benytter Foreldrepenger.no!</P>
                    <P style={{fontSize: '14px', fontWeight: 'bold'}}>Din pdf er på vei til deg på epost!</P>
                </>:<>
                    <H2>{'Din betaling feilet desverre'}</H2>
                    <P style={{fontSize: '14px', fontWeight: 'bold'}}> Forsøk veldig gjerne igjen med en annen betalingsmetode</P>
                </>}
                <CloseButton onClick={props.close}>x</CloseButton>
            </InnerModalContainer>
        </Modal>
    );
};

export default PaymentConfirmationMessageModal;