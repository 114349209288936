import React from "react";
import styled from "styled-components";
import Month from "./Month";
import { getMonthDiff } from "../../utils/permSectionUtils";
import { color } from "../../theme/standardTheme";

const StyledCalendar = styled.div`
    display: grid; 
    gird-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(9, 69px);
    grid-auto-rows: 72px;
    min-width: 10px;  
    min-height: 10px;
    row-gap: 5px;
    column-gap: 5px;
    padding: 5px;

    @media (max-width: 750px) {
        display: flex; 
        flex-direction: column;

    }
`

const Calendar = (props) => {
    return(
        <StyledCalendar>
            {props.months.map((month, ix) => {
                    var col = 1;
                    var row;
                    if ((month.number % 2) === 0) {
                        row = month.number/2 + 1;
                    } else {
                        col = 2;
                        row = (month.number-1)/2 + 1;
                    }

                    var monthSections;
                    if (props.sections.length){
                        monthSections = props.sections.filter(sec => (sec.startDate.getMonth() === month.calendarNumber && sec.startDate.getFullYear() === month.year));
                    }

                    var mnt = month; 
                    var leapYear = ((month.year % 4 == 0) && (month.year % 100 != 0)) || (month.year % 400 == 0)
                    if (leapYear && month.name==='Februar') mnt.numberOfDays = 29;

                    var checkDate = new Date(month.year, month.calendarNumber, 15, 13);

                    var addTermDate = false; 
                    if (props.termDate !== ''){
                        if (props.termDate.getDate() === 1 && getMonthDiff(props.termDate, checkDate) === 0) {
                            addTermDate = true; 
                        }
                    }

                    return(
                        <Month
                            key={ix}
                            col={col}
                            row={row}
                            monthName={month.name}
                            color={color.secondary}
                            fr={mnt.numberOfDays}
                            year= {month.year}
                            sections={monthSections}
                            termDate={props.termDate}
                            checkDate={checkDate}
                            addTermDate = {addTermDate}
                            age={month.number-1}
                        >
                        </Month>
                    )
               })}
        </StyledCalendar>
    )
}

export default Calendar;
