import styled from "styled-components";
import { borderRaduis, color } from "../theme/standardTheme";

const InnerModalContainer = styled.div`
    position: relative;
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    padding: 30px 10px; 
    background-color: ${color.secondary};
    border-radius: ${borderRaduis.large};
    min-width: 250px;
    min-height: 100px; 
    width: 80%;
    box-shadow: 0px 0px 100px ${color.fontSecondary};
    overflow: auto; /* Enable scroll if needed */

    @media (max-width: 1240px) {
        width: 95%;
    }
    @media (max-width: 750px) {
        width: 94%;
        margin-top: 200px;
    }
    
`

export default InnerModalContainer;