import React from "react";
import InnerModalContainer from "../containers/InnerModalContainer";
import { H4, H5, P, UL, LI } from "../theme/text";
import { color } from "../theme/standardTheme";

const InnerModalPermSections = (props) => {

    return(
        <InnerModalContainer>
                <H4>Hvordan planlegge permisjonen deres</H4>
                <P>
                    Det er mange ting man må ta hensyn til når man planlegger foreldrepermisjon. <br/>
                    her er noen forslag:
                </P>
                <UL>
                    <LI><P>Finn ut når dere har rett på barnehageplass</P></LI>
                    <LI><P>Bruk ferie godt og strategisk, husk at dere kan telle 5 uker på hver side av nytt år</P></LI>
                    <LI><P>Vurder hvorvidt 100% eller 80% lønnsutbetaling passer dere best</P></LI>
                    <LI><P>For noen er det lurt å ta ut ulønnet permosjon om situasjonen tilater dette</P></LI>
                </UL>
                <P>
                    Kos dere med planleggingen, det er en fantastisk tid dere har i vente!
                </P>
                <a style={{fontWeight: "bold", color: color.font, marginTop: "20px" }} href="https://www.foreldrepenger.no//lonn-og-permisjonspenger" target="_blank">Les mer</a>
            </InnerModalContainer>
    )
}

export default InnerModalPermSections;