import styled, { css, keyframes } from "styled-components";
import { borderRaduis } from "../theme/standardTheme";

const expandVertical = keyframes`
    from { height: 30px; }
    to { height: 120px; }
`
const expandHorizontal = keyframes`
    from { width: 200px; }
    to { width: 97%; }
`

const expandAnimation = css`
    animation: ${expandVertical} 0.2s linear, ${expandHorizontal} 0.2s linear;
    animation-fill-mode: forwards;
`

const shrinkAnimation = css`
    animation: ${expandVertical} 0.2s linear, ${expandHorizontal} 0.2s linear;
    animation-fill-mode: forwards;
    animation-direction: reverse;
`

const PermInputContainer = styled.div`
    display: flex; 
    justify-content: center; 
    align-items: center;
`


const AddSectionToolContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    gap: 4px;
    width: 97%;
    height: 100px;
    min-height: 30px;
    min-width: 200px;
    max-width: 450px;
    border-radius: ${borderRaduis.large};
    margin: 8px; 
    background-color: ${props => props.color? props.color: "red"};
    padding: 5px;

    ${props => (props.expand==='expand' && false)? 
        expandAnimation
    :""}
    ${props => (props.expand==='shrink'&& false)?
        shrinkAnimation
    :""}
`
export  {
    AddSectionToolContainer,
    PermInputContainer,
};