import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { P, H4, H5 } from "../theme/text";
import { getPermColor } from "../utils/permSectionUtils";
import {ReactComponent as ArrowSVG} from '../icons/arrow-triangle-hat.svg'
import {ReactComponent as DeleteSVG} from '../icons/trash.svg'
import { color, font } from "../theme/standardTheme";
import EditIcon from "./EditIcon";
import TopicButton from "./Buttons/TopicButton";
import DropdownMenu from "./DropdownMenu";
import InputField from "./Fields/InputField";
import OkIcon from "./OkIcon";
import CrossIcon from "./CrossIcon";

const PeriodContainer = styled.div`
    display: grid; 
    grid-template-columns: 2fr 4fr 4fr 1fr 1fr;
    gric-template-rows: 1fr 1fr;
    align-items: center; 
    justify-items: center;
    background-color: ${props => props.color? props.color: "red"};
    width: 100%;
    max-width: ${props => (props.type==='fodsel')? "400px" : "500px"};
    height: 45px; 
    margin: 5px; 
    border-radius: 100px; 
    
`

// stroke or fill
const Arrow = styled(ArrowSVG)`
    path {
        fill: ${color.fontSecondary}; 
    };
    height: 20px;
    width: 20px; 
    
`
const Delete = styled(DeleteSVG)`
    path {
        stroke: ${color.fontSecondary}; 
    };
    height: 28px;
    width: 28px;
`

const DurationContainer = styled.div`
    display: flex;
    justify-content: center; 
    align-items: center; 
    justify-self: start; 
`


const Period = (props) => {

    const [editing, setEditing] = useState(false);
    const [newDuration, setNewDuration] = useState(props.period.duration);
    const [newPermType, setNewPermType] = useState({ value: props.period.type, label: props.period.shortName });

    const deleteStyles = {
        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 1,
        gridRowEnd: 3,
    }

    const periodNameStyle = {
        gridColumnStart: 2,
        gridColumnEnd: 4,
        gridRowStart: 1,
        gridRowEnd: 2,
        margin: "0px",
        color: color.fontSecondary
    }

    const editIconStyles = {
        gridColumnStart: 4,
        gridColumnEnd: 5,
        gridRowStart: 1,
        gridRowEnd: 3,
        marginRight: "12px"
    }

    const OkIconStyle = {
        gridColumnStart: 4,
        gridColumnEnd: 5,
        gridRowStart: 1,
        gridRowEnd: 3,
        marginRight: "12px"
    }
    const crossIconStyle = {
        gridColumnStart: 5,
        gridColumnEnd: 6,
        gridRowStart: 1,
        gridRowEnd: 3,
        marginRight: "15px"
    }

    const dropdownStyle = {    
        gridColumnStart: 1,
        gridColumnEnd: 3,
        gridRowStart: 1,
        gridRowEnd: 3,
    }

    const durationContainerStyle = {
        gridColumnStart: 3,
        gridColumnEnd: 4,
        gridRowStart: 1,
        gridRowEnd: 3,
    }

    const dateStyle = {
        gridColumnStart: 2,
        gridColumnEnd: 4,
        gridRowStart: 2,
        gridRowEnd: 3,
        margin: "0px",
        color: color.fontSecondary
    }

    function saveEditedPeriod(){
        setEditing(false);
        props.editPeriod(props.period.id, newDuration, newPermType.value, newPermType.label); 
    }

    function abortEdit(){
        setEditing(false);
        setNewDuration(props.period.duration);
        setNewPermType({ value: props.period.type, label: props.period.name });
    }

    function setPermTypeHandler(item) {
        setNewPermType(item);
    }

    return (
        <> 
            {(editing && props.period.type != 'fodsel')?
                <PeriodContainer color={props.color} type={props.period.type}>
                    <DropdownMenu
                        style={dropdownStyle}
                        permType={newPermType}
                        setPermType={setPermTypeHandler}
                        select={props.period.type}
                        small
                    />
                    <DurationContainer style={durationContainerStyle}>
                        <H5 style={{margin: "0px 10px", justifySelf: "end", color: color.fontSecondary}}>Uker:</H5>
                        <InputField
                            type="number"
                            pattern="[0-9]*"
                            value={newDuration}
                            update={setNewDuration}
                            maxWidth={"70px"}
                        />
                    </DurationContainer>
                    <OkIcon style={OkIconStyle} color={color.signalGreen} onClick={saveEditedPeriod} />
                    <CrossIcon style={crossIconStyle} color={color.signalRed} onClick={abortEdit}/>
                </PeriodContainer>
            :
                <PeriodContainer color={props.color} type={props.period.type}>
                    {(props.period.type === 'fodsel')?<div style={deleteStyles} />:
                        <Delete style={deleteStyles} onClick={() => props.deletePeriod(props.period.id)} />
                    }
                    <H5 style={periodNameStyle} >{props.period.name}</H5>
                    {(props.period.type === 'fodsel')?<div style={editIconStyles} />:
                        <EditIcon style={editIconStyles} color={color.fontSecondary} onClick={() => setEditing(true)}></EditIcon>
                    }
                    
                    {(props.period.type === 'fodsel')?<div/>:
                        <Arrow style={{rotate: '180deg', marginRight: "15px"}} onClick={()=> props.shufflePeriod('up', props.period.id)}/>
                    }
                    <H5 style={dateStyle} >Dato: {props.period.startDate.getDate()}.{props.period.startDate.getMonth()+1}.{props.period.startDate.getFullYear()}</H5>
                    {(props.period.type === 'fodsel')?<div/>:
                        <Arrow style={{rotate: '0deg', marginRight: "15px"}} onClick={()=> props.shufflePeriod('down', props.period.id)}/>
                    }
                </PeriodContainer>
            }
        </>
    )
}

export default Period;