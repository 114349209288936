
const color = {
    primary: "white", // Background
    secondary: "#F4EEED", // Frame
    tertiary: "#EFD9D1", // Buttons
    burgundy: "#DDB7AB",
    termMarker: "#C44F68",
    font: "#937571", // Header (green isj)
    fontSecondary: "#565656", // general font
    signalGreen: "#00DF75",
    signalRed: "#EE005F"

}

// #F4EEED - baige
// #EFD9D1 - pink-baige
// #DDB7AB - burgundy
// #999B84 - earth-green


const borderRaduis = {
    large: "20px",
    medium: "10px",
    small: "5px",
}

const font = "Georgia, Times, serif"

export {
    color,
    borderRaduis,
    font,
}