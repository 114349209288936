import axios from "axios"
import { appConfig } from "../../appConfig";

const host = appConfig.host;

const paymentTypes = {
    CARD: 'CARD',
    VIPPS: 'VIPPS',
}

const getProducts = async (req, res) => {
    const call = {
        method: "get",
        url: `${host}/payments/products`,
    }
    try {
        const response = await axios(call);
        const products = response.data;
        return products;
    } catch (e) {
        return false;
    }   
}

const initiatePurchase = async (product, email, paymentType) => {
    const call = {
        method: "post",
        url: `${host}/payments/`,
        data: {
            product: product,
            email: email,
            paymentType: paymentTypes[paymentType]
        }
    }
    try {
        const response = await axios(call);
        console.log(response.data);
        if (response.data.error) return false;
        else if (response.data) return response.data;
        else return false;
    } catch (e) {
        return false;
    }
}

const checkStripeSessionStatus = async (sessionId) => {
    const call = {
        method: "get",
        url: `${host}/payments/stripe-session-status?session_id=${sessionId}`,
    }
    try {
        const response = await axios(call);
        return response.data.status;
    } catch (e) {
        return false;
    }
}

const checkVippsSessionStatus = async (sessionId, isSubscription) => {
    const call = {
        method: "get",
        url: `${host}/payments/vipps-session-status?session_id=${sessionId}&isSubscription=${isSubscription}`,
    }
    try {
        const response = await axios(call);
        return response.data.status;
    } catch (e) {
        return false;
    }
}

export {
    paymentTypes,
    getProducts,
    initiatePurchase,
    checkStripeSessionStatus,
    checkVippsSessionStatus,
};