import React, { useEffect, useState } from "react";
import EconomyInput from "../components/EconomyInput";
import InputWrapper from "../containers/InputWrapper";
import EconomyViewContainer from "../containers/EconomyViewContainer";
import EconomyResultContainer from "../containers/EconomyResultContainer";
import ResultContainer from "../containers/ResultContainer";
import DividerLine from "../components/DividerLine";
import { H4, P, H5, UL, LI} from "../theme/text";
import LineChart from "../components/LineChart";
import HorizontalChartWrapper from "../containers/HorizontalChartWrapper";
import { perm100Grenser, perm80Grenser } from "../utils/permisjonsUtils";
import Slider from "../components/Slider";
import { color } from "../theme/standardTheme";
import Summary from "../components/Summary";
import SummaryCombined from "../components/SummaryCombined";
import useScreenSize from "../hooks/useScreenSize";



const EconomyView = (props) => {

    const screenSize = useScreenSize();

    useEffect(() => {
        updatePaymentPlans100();
        updatePaymentPlans80();
    }, []);

    useEffect(() => {
        updatePaymentPlans100();
        updatePaymentPlans80();
    }, [props.payments, props.perm100, props.perm80]);

    function updatePaymentPlans100() {
        const morPermPenger = props.payments.permUkeMor;
        const farPermPenger = props.payments.permUkeFar;
        const morUkeLonn = props.payments.ukeMor;
        const farUkeLonn = props.payments.ukeFar;
        var paymentPlan = [];
        var totalPaymentPlan = []
        const momWeeks = props.perm100.ukerMor;
        const dadWeeks = props.perm100.ukerFar;
        for (let i=0; i<momWeeks; i++){
            paymentPlan.push((i+1)*morPermPenger)
            totalPaymentPlan.push((i+1)*(morPermPenger+farUkeLonn));
        }
        var permCash = paymentPlan[paymentPlan.length-1];
        var totalCash = totalPaymentPlan[totalPaymentPlan.length-1];
        for (let j=0; j<dadWeeks; j++) {
            paymentPlan.push((j+1)*farPermPenger + permCash);
            totalPaymentPlan.push((j+1)*(farPermPenger+morUkeLonn) + totalCash);
        }
        permCash = paymentPlan[paymentPlan.length-1];
        totalCash = totalPaymentPlan[totalPaymentPlan.length-1];
        var rest = perm80Grenser.uker - momWeeks - dadWeeks + 10;
        var ulonnetPermTeller = 0;
        var ulonnetPermUker = props.perm100.ulonnetPermFar + props.perm100.ulonnetPermMor;
        var vektetLonn = hentVektetUlonnetPermisjonsLonn();
        var akumulertVektetLønn = 0;
        for (let k=0; k<rest; k++){
            if(ulonnetPermTeller >= ulonnetPermUker) vektetLonn = 0;
            else {
                ulonnetPermTeller++;
                akumulertVektetLønn = akumulertVektetLønn + vektetLonn;
            };
            paymentPlan.push(permCash);
            totalPaymentPlan.push(totalCash + (k+1)*(morUkeLonn+farUkeLonn) - akumulertVektetLønn);
        }
        props.setPaymentPlan100(paymentPlan);
        props.setTotalPaymentPlan100(totalPaymentPlan);
    }

    function updatePaymentPlans80() {
        const morPermPenger = props.payments.permUkeMor;
        const farPermPenger = props.payments.permUkeFar;
        const morUkeLonn = props.payments.ukeMor;
        const farUkeLonn = props.payments.ukeFar;
        var paymentPlan = [];
        var totalPaymentPlan = []
        const momWeeks = props.perm80.ukerMor;
        const dadWeeks = props.perm80.ukerFar;
        const factor80 = props.payments.paymentFactor;
        for (let i=0; i<momWeeks; i++){
            paymentPlan.push((i+1)*factor80*morPermPenger)
            totalPaymentPlan.push((i+1)*(factor80*morPermPenger+farUkeLonn));
        }
        var permCash = paymentPlan[paymentPlan.length-1];
        var totalCash = totalPaymentPlan[totalPaymentPlan.length-1];
        for (let j=0; j<dadWeeks; j++) {
            paymentPlan.push((j+1)*factor80*farPermPenger + permCash);
            totalPaymentPlan.push((j+1)*(factor80*farPermPenger+morUkeLonn) + totalCash);
        }
        permCash = paymentPlan[paymentPlan.length-1];
        totalCash = totalPaymentPlan[totalPaymentPlan.length-1];
        var rest = perm80Grenser.uker - momWeeks - dadWeeks + 10;
        var ulonnetPermTeller = 0;
        var ulonnetPermUker = props.perm80.ulonnetPermFar + props.perm80.ulonnetPermMor;
        var vektetLonn = hentVektetUlonnetPermisjonsLonn();
        var akumulertVektetLønn = 0;
        for (let k=0; k<rest; k++) {
            if(ulonnetPermTeller >= ulonnetPermUker) vektetLonn = 0;
            else {
                ulonnetPermTeller++;
                akumulertVektetLønn = akumulertVektetLønn + vektetLonn;
            };
            paymentPlan.push(permCash);
            totalPaymentPlan.push(totalCash + (k+1)*(morUkeLonn+farUkeLonn)) // usikker på om permisjon skal tas med (akkumulert vektet)
        }
        props.setPaymentPlan80(paymentPlan);
        props.setTotalPaymentPlan80(totalPaymentPlan);
    }

    
    function hentPermisjonsPenger100() {
        return Math.round(props.perm100.ukerFar*props.payments.permUkeFar + props.perm100.ukerMor*props.payments.permUkeMor);
    }

    function hentPermisjonsPenger80() {
        return Math.round(props.payments.paymentFactor*props.perm80.ukerFar*props.payments.permUkeFar + props.payments.paymentFactor*props.perm80.ukerMor*props.payments.permUkeMor)
    }

    function hentUtbetalt100Etter59() {
        return Math.round(
            props.perm100.ukerFar*props.payments.permUkeFar 
            + props.perm100.ukerMor*props.payments.permUkeMor 
            + props.payments.ukeFar*(perm80Grenser.uker-props.perm100.ukerFar) 
            + props.payments.ukeMor*(perm80Grenser.uker-props.perm100.ukerMor)
            )
    }

    function hentUtbetalt80Etter59(){
        return Math.round(
            props.payments.paymentFactor*props.perm80.ukerFar*props.payments.permUkeFar 
            + props.payments.paymentFactor*props.perm80.ukerMor*props.payments.permUkeMor
            + props.payments.ukeFar*(perm80Grenser.uker-props.perm80.ukerFar) 
            + props.payments.ukeMor*(perm80Grenser.uker-props.perm80.ukerMor)
            )
    }

    function hentAlleMorUker100() {
        return (props.perm100.ukerMor + props.perm100.ferieMor + props.perm100.ulonnetPermMor);
    }

    function hentAlleFarUker100() {
        return (props.perm100.ukerFar + props.perm100.ferieFar + props.perm100.ulonnetPermFar);
    }

    function hentAlleMorUker80() {
        return (props.perm80.ukerMor + props.perm80.ferieMor + props.perm80.ulonnetPermMor)
    }

    function hentAlleFarUker80() {
        return (props.perm80.ukerFar + props.perm80.ferieFar + props.perm80.ulonnetPermFar)
    }

    function hentDisponibleUker100() {
        return (props.perm100.ukerMor
            + props.perm100.ferieMor
            + props.perm100.ulonnetPermMor
            + props.perm100.ukerFar
            + props.perm100.ferieFar
            + props.perm100.ulonnetPermFar)
    }

    function hentDisponibleUker80() {
        return (props.perm80.ukerMor
            + props.perm80.ferieMor
            + props.perm80.ulonnetPermMor
            +props.perm80.ukerFar
            + props.perm80.ferieFar
            + props.perm80.ulonnetPermFar)
    }

    function hentPermisjonsDifferanse() {
        return (hentPermisjonsPenger100() - hentPermisjonsPenger80());
    }

    function hentTotalDifferanse() {
        var l = props.totalPaymentPlan100.length
        var tot100 = props.totalPaymentPlan100[l-1];
        var tot80 = props.totalPaymentPlan80[l-1];
        return (tot100-tot80);
    }

    function hentVektetUlonnetPermisjonsLonn() {
        var morUkeLonn = props.payments.ukeMor;
        var farUkeLonn = props.payments.ukeFar;
        var uPermMor = props.perm100.ulonnetPermMor;
        var uPermFar = props.perm100.ulonnetPermFar;
        var sumUker = uPermFar + uPermMor;
        if ((sumUker) < 1) return 0;
        var vektetLonn = (uPermFar*farUkeLonn + uPermMor*morUkeLonn) / sumUker;
        return vektetLonn;
    }

    return(
        <EconomyViewContainer>
                <EconomyInput
                    color={props.themeColor}
                    perm100={props.perm100}
                    perm80={props.perm80}
                    updatePerm100={props.updatePerm100}
                    updatePerm80={props.updatePerm80}
                    payments={props.payments}
                    setPayments={props.setPayments}
                />
            
            <EconomyResultContainer>
                {(screenSize.width > 700)?
                    <>
                        <Summary
                            permType={100}
                            perm={props.perm100}
                            hentAlleMorUker={hentAlleMorUker100}
                            hentAlleFarUker={hentAlleFarUker100}
                            hentDisponibleUker={hentDisponibleUker100}
                            hentPermisjonsPenger={hentPermisjonsPenger100}
                            hentUtbetaltEtter59={hentUtbetalt100Etter59}
                        />
                        <Summary
                            permType={80}
                            perm={props.perm80}
                            hentAlleMorUker={hentAlleMorUker80}
                            hentAlleFarUker={hentAlleFarUker80}
                            hentDisponibleUker={hentDisponibleUker80}
                            hentPermisjonsPenger={hentPermisjonsPenger80}
                            hentUtbetaltEtter59={hentUtbetalt80Etter59}
                        />
                    </>
                :
                    <SummaryCombined
                        perm100={props.perm100}
                        hentAlleMorUker100={hentAlleMorUker100}
                        hentAlleFarUker100={hentAlleFarUker100}
                        hentDisponibleUker100={hentDisponibleUker100}
                        hentPermisjonsPenger100={hentPermisjonsPenger100}
                        hentUtbetalt100Etter59={hentUtbetalt100Etter59}
                        perm80={props.perm80}
                        hentAlleMorUker80={hentAlleMorUker80}
                        hentAlleFarUker80={hentAlleFarUker80}
                        hentDisponibleUker80={hentDisponibleUker80}
                        hentPermisjonsPenger80={hentPermisjonsPenger80}
                        hentUtbetalt80Etter59={hentUtbetalt80Etter59}
                    />
                    
                }
                
                
                <ResultContainer color={props.themeColor.secondary} cs={1} ce={-1} rs={-2} re={-1}>
                    <H4>Sammenlikning mellom 80% og 100% utbetaling</H4>
                    <P style={{maxWidth: "650px"}}>
                        <UL>
                            {((hentPermisjonsDifferanse()) > 0)?
                                <LI>Dere får {Math.round(hentPermisjonsDifferanse())} mer i permisjonspenger ved å velge 100% sammenliknet med 80% utbetaling.</LI>:
                                <LI>Dere får {Math.round(0 - hentPermisjonsDifferanse())} mer i permisjonspenger ved å velge 80% sammenliknet med 100% utbetaling.</LI>
                            }
                            {((hentTotalDifferanse()) > 0)?
                                <LI>Dere får {Math.round(hentTotalDifferanse())} mer utbetalt etter {perm80Grenser.uker} ved å velge 100% sammenliknet med 80% utbetaling.</LI>:
                                <LI>Dere får {Math.round(0 - hentTotalDifferanse())} mer utbetalt etter {perm80Grenser.uker} ved å velge 80% sammenliknet med 100% utbetaling.</LI>
                            }
                            <LI>Mor kan legge til {Math.round(hentTotalDifferanse()/props.payments.ukeMor)} uker ulønnet permisjon <span style={{fontWeight: "bold"}}>eller</span> far kan legge til {Math.round(hentTotalDifferanse()/props.payments.ukeFar)} uker ulønnet permisjon og være i likevekt med å velge 80% utbetaling.</LI>
                            <LI>Det er fornuftig å vurdere 100% lønn i kombinasjon med ulønnet permisjon, fordelingen mellom dere påvirker om det er økonomisk gunstig. </LI>
                        </UL>
                    </P>
                    <LineChart
                        title={"Utbetaling av permisjonspenger"}
                        titleColor={color.font}
                        x = {props.weeks}
                        y1 = {props.paymentPlan100}
                        color1={color.font}
                        y2 = {props.paymentPlan80}
                        color2={color.burgundy}
                    />
                    <LineChart
                        title={"Deres økonomi, inkludert lønn"}
                        titleColor={color.font}
                        x = {props.weeks}
                        y1 = {props.totalPaymentPlan100}
                        color1={color.font}
                        y2 = {props.totalPaymentPlan80}
                        color2={color.burgundy}
                    />
                </ResultContainer>
            </EconomyResultContainer>
        </EconomyViewContainer>
    );
}


export default EconomyView