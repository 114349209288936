import './App.css';
import React, { useState } from 'react';
import AppContainer from './containers/AppContainer';
import { color } from './theme/standardTheme';
import Menu from './components/Menu/Menu';
import EconomyView from './views/EconomyView';
import { getUpdatedPerm100, getUpdatedPerm80, perm100Grenser, perm80Grenser } from './utils/permisjonsUtils';
import PlanningView from './views/PlanningView';
import { Helmet } from 'react-helmet';
import { appConfig } from './appConfig';

var perm100Valg = {
  ukerFar: 23,
  ukerMor: 26,
  ulonnetPermFar: 0,
  ulonnetPermMor: 0,
  ferieMor: 10, 
  ferieFar: 10,
}

var perm80Valg = {
  ukerFar: 29,
  ukerMor: 32,
  ulonnetPermFar: 0,
  ulonnetPermMor: 0,
  ferieMor: 10, 
  ferieFar: 10,
}

var paymentsDefault = {
  mor: 20000,
  far: 20000,
  permMor: 20000, 
  permFar: 20000,
  ukeMor: 20000*0.231,
  ukeFar: 20000*0.231,
  permUkeMor: 20000*0.231,
  permUkeFar: 20000*0.231,
  ukeFaktor: 0.231,
  paymentFactor: 0.8,
}

function App() {

  const [view, setView] = useState(appConfig.activeApp); // plan || eco

  const [perm100, setPerm100] = useState(perm100Valg);
  const [perm80, setPerm80] = useState(perm80Valg);

  const [payments, setPayments] = useState(paymentsDefault)
  const [paymentPlan100, setPaymentPlan100] = useState(new Array(69).fill(0));
  const [totalPaymentPlan100, setTotalPaymentPlan100] = useState(new Array(69).fill(0));
  const [paymentPlan80, setPaymentPlan80] = useState(new Array(69).fill(0));
  const [totalPaymentPlan80, setTotalPaymentPlan80] = useState(new Array(69).fill(0));
  const [weeks, setWeeks] = useState(Array.from(Array(69).keys()));

  function updateUkerFar100(uker){
    if (uker < perm100Grenser.minFar) uker = perm100Grenser.minFar;
    else if (uker > perm100Grenser.maxFar) uker = perm100Grenser.maxFar;
    var updatedPerm = getUpdatedPerm100(uker, 'ukerFar', perm100);
    setPerm100(updatedPerm);
  }

  function updateUkerMor100(uker){
    if (uker < perm100Grenser.minMor) uker = perm100Grenser.minMor;
    else if (uker > perm100Grenser.maxMor) uker = perm100Grenser.maxMor;
    var updatedPerm = getUpdatedPerm100(uker, 'ukerMor', perm100);
    setPerm100(updatedPerm);
  }

  function updateUlonnetPermFar100(uker){
    var updatedPerm = getUpdatedPerm100(uker, 'ulonnetPermFar', perm100);
    setPerm100(updatedPerm);
  }

  function updateUlonnetPermMor100(uker){
    var updatedPerm = getUpdatedPerm100(uker, 'ulonnetPermMor', perm100);
    setPerm100(updatedPerm);
  }

  function updateFerieFar100(uker){
    var updatedPerm = getUpdatedPerm100(uker, 'ferieFar', perm100);
    setPerm100(updatedPerm);
  }

  function updateFerieMor100(uker){
    var updatedPerm = getUpdatedPerm100(uker, 'ferieMor', perm100);
    setPerm100(updatedPerm);
  }

  ///////////////////

  function updateUkerFar80(uker){
    if (uker < perm80Grenser.minFar) uker = perm80Grenser.minFar;
    else if (uker > perm80Grenser.maxFar) uker = perm80Grenser.maxFar;
    var updatedPerm = getUpdatedPerm80(uker, 'ukerFar', perm80);
    setPerm80(updatedPerm);
  }

  function updateUkerMor80(uker){
    if (uker < perm80Grenser.minMor) uker = perm80Grenser.minMor;
    else if (uker > perm80Grenser.maxMor) uker = perm80Grenser.maxMor;
    var updatedPerm = getUpdatedPerm80(uker, 'ukerMor', perm80);
    setPerm80(updatedPerm);
  }

  function updateUlonnetPermFar80(uker){
    var updatedPerm = getUpdatedPerm80(uker, 'ulonnetPermFar', perm80);
    setPerm80(updatedPerm);
  }

  function updateUlonnetPermMor80(uker){
    var updatedPerm = getUpdatedPerm80(uker, 'ulonnetPermMor', perm80);
    setPerm80(updatedPerm);
  }

  function updateFerieFar80(uker){
    var updatedPerm = getUpdatedPerm80(uker, 'ferieFar', perm80);
    setPerm80(updatedPerm);
  }

  function updateFerieMor80(uker){
    var updatedPerm = getUpdatedPerm80(uker, 'ferieMor', perm80);
    setPerm80(updatedPerm);
  }

  const updatePerm100 = {
    ukerFar: updateUkerFar100,
    ukerMor: updateUkerMor100,
    ulonnetPermFar: updateUlonnetPermFar100,
    ulonnetPermMor: updateUlonnetPermMor100,
    ferieFar: updateFerieFar100,
    ferieMor: updateFerieMor100
  }

  const updatePerm80 = {
    ukerFar: updateUkerFar80,
    ukerMor: updateUkerMor80,
    ulonnetPermFar: updateUlonnetPermFar80,
    ulonnetPermMor: updateUlonnetPermMor80,
    ferieFar: updateFerieFar80,
    ferieMor: updateFerieMor80
  }

  return (
    <AppContainer color={color.primary}>
      <Helmet>
          {/*<meta charSet="utf-8" />*/}
          <title>Permisjonsplanlegger</title>
          {/*<link rel="canonical" href="http://mysite.com/example" />*/}
          <style>
            {`
              body {
                background-color: ${color.primary};
              }
            `}
          </style>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0"></meta>
          <script // Vipps button
            async
            type="text/javascript"
            src="https://checkout.vipps.no/checkout-button/v1/vipps-checkout-button.js"
            ></script>
      </Helmet>
      {/*<Menu
        setView={setView}
        themeColor={color}
      />*/}
      {(view === 'eco')? 
        <EconomyView
          themeColor={color}
          perm80={perm80}
          perm100={perm100}
          updatePerm80={updatePerm80}
          updatePerm100={updatePerm100}

          payments={payments}
          setPayments={setPayments}
          paymentPlan80={paymentPlan80}
          paymentPlan100={paymentPlan100}
          setPaymentPlan80={setPaymentPlan80}
          setPaymentPlan100={setPaymentPlan100}
          totalPaymentPlan80={totalPaymentPlan80}
          totalPaymentPlan100={totalPaymentPlan100}
          setTotalPaymentPlan80={setTotalPaymentPlan80}
          setTotalPaymentPlan100={setTotalPaymentPlan100}
          weeks={weeks}
        />
      :<></>}
      {(view === 'plan')?
        <PlanningView
          themeColor={color}
        />
      :<></>}
    </AppContainer>
  );
}

export default App;
