import { server } from "./server/server";

const ENV = 'dev' // prod || dev
const APP = 'plan' // plan || eco

///////////   CONFIG ABOVE   ///////////
////////////////////////////////////////

const appConfig = {
    env: ENV,
    activeApp: APP,
}
if (appConfig.env === 'prod') appConfig.host = server.remote;
else appConfig.host = server.local;

export {
    appConfig,
}