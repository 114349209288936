import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { H2, H3, H5, H4, P } from "../../theme/text";
import InnerModalContainer from "../../containers/InnerModalContainer";
import TopicButton from "../../components/Buttons/TopicButton";
import { color } from "../../theme/standardTheme";
import { subscribe, testPost } from "./pdfUtils";
import InputField from "../../components/Fields/InputField";
import Checkbox from "../../components/Checkbox";
import RadioCheckboxContainer from "../../components/PlanningInput/RadioCheckboxContainer";
import Product from "../../components/Product";
import { initiatePurchase, paymentTypes } from '../../server/api/payments'
import Checkout from "../../components/Checkout/Checkout";

const ProductContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;

    @media (max-width: 700px) {
        gap: 5px;
    }

    @media (max-width: 600px) {
        flex-direction: column;
    }
`

const CloseButton = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    display: inline-block;
    padding: 8px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: ${color.fontSecondary};
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
`

const InnerModalWantPdf = (props) => {

    const [email, setEmail] = useState(props.userEmail || '');
    const [falseEmail, setFalseEmail] = useState(false);
    const [subscribeToMail, setSubscribeToMail] = useState(true);
    const [products, setProducts] = useState(props.products || []);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [checkout, setCheckout] = useState(false);
    const [purchaseSuccess, setPurchaseSuccess] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const [emailProvided, setEmailProvided] = useState(false);


    useEffect(() => {
        setProducts(props.products);
    }, [props.products]);

    useEffect(() => {
        if (!props.show){
            setClientSecret(null);
        }
    }, [props.show]);

    function verifiedEmail(){
        var lastChar = email.substring(email.length - 1);
        if(
            !email.includes('@') ||
            !email.includes('.') ||
            lastChar==='@' ||
            lastChar ==='.' ||
            email.indexOf('@') > email.lastIndexOf('.')
        ) return false;
        else return true;
    }

    function goToPayments() {
        if (!verifiedEmail()){
            setFalseEmail(true);
            setEmailProvided(false);
            return;
        }
        props.mixpanelTrackEvent('Email Posted', {email: email});
        setEmailProvided(true);
    }


    async function purchase (product, paymentType='CARD') {
        if (!verifiedEmail()){
            setFalseEmail(true);
            setEmailProvided(false);
            return;
        }
        props.mixpanelTrackEvent('Product', {product: {name: product.name, paymentFrequency: product.paymentFrequency}});
        setSelectedProduct(product);
        props.storeCookies('userEmail', email);

        if (paymentType === 'VIPPS'){
            console.log('Integrate vipps payment');
            var data = await initiatePurchase(product, email, paymentTypes.VIPPS);
            console.log(data);
            const vippsUrl = data?.purchaseData?.redirectUrl;
            if (vippsUrl) window.top.location.href = vippsUrl; //window.open(vippsUrl, '_blank');
            else console.log('Error in vipps payment)'); // handle error
        } else {
            var paymentSession = await initiatePurchase(product, email, paymentTypes.CARD); 
            console.log(paymentSession);
            if (paymentSession){
                setClientSecret(paymentSession.purchaseData.clientSecret);
            }
        }
    }

    function closeHandler() {
        setEmailProvided(false);
        props.close();
    }

    function sendPdfToPayingSubscriber(){
        props.sendPdfHandler();
        props.close();
    }

    return(
        <InnerModalContainer  onClick={(event) => event.stopPropagation()} >
            {clientSecret && selectedProduct? 
                <Checkout product={selectedProduct} clientSecret={clientSecret} ></Checkout>
            :<>
                {!emailProvided?<>
                    <H2  style={{textAlign: 'center'}}>Få din permisjon tilsendt på epost!</H2>
                    <P  style={{fontSize:"18px", textAlign: 'center'}}>
                        Dette er veldig praktisk til du skal søke hos NAV eller vil gjøre små endringer senere.
                    </P>
                    <br/>
                    <InputField
                        label= ""
                        placeholder = "din epost"
                        update = {setEmail}
                        maxWidth = {'500px'}
                        type = {'email'}
                        autocomplete={"on"}
                        id="email"
                        value={props.userEmail || ''}
                    />

                    {props.signedIn?
                        <TopicButton
                            style={{width: "170px", margin: "20px"}}
                            onClick={() => sendPdfToPayingSubscriber()}
                            >Send meg planen!
                        </TopicButton>
                    : 
                        <TopicButton
                            style={{width: "170px", margin: "20px"}}
                            onClick={() => goToPayments()}
                            >Send meg planen!
                        </TopicButton>
                    }
                    
                    {falseEmail?
                        <P style={{fontSize: '14px', fontWeight: 'bold', color: 'black'}}>Du må oppgi en gyldig epost slik at vi kan sende planen din til deg</P>
                    :<></>}
                </>:<>

                    <H2 style={{textAlign: 'center'}}>Ta vare på permisjonsplanen din!</H2>
                    <P style={{fontSize:"18px", textAlign: 'center'}}>
                        Det er en spennende tid i vente, vi ønsker deg lykke til!
                    </P>
                    <ProductContainer>
                        <Product
                            product={products['SUBSCRIPTION_MONTHLY'] || {}}
                            productVariation={products['SUBSCRIPTION_YEARLY'] || {}}
                            purchase={purchase}
                            btnText={'Fortsett med kort'}
                            animate={true}
                        />
                        <Product
                            product={products['PDF'] || {}}
                            purchase={purchase}
                            btnText={'Betal med kort'}
                        />
                    </ProductContainer>
                </>}
            </> }
            <CloseButton onClick={closeHandler}>x</CloseButton>
        </InnerModalContainer>
    )
}

export default InnerModalWantPdf;