import React, { useEffect, useState } from "react";
import { H4, H5, P } from "../theme/text";
import {AddSectionToolContainer, PermInputContainer} from "../containers/AddSectionToolContainer";
import TopicButton from "./Buttons/TopicButton";
import DropdownMenu from "./DropdownMenu";
import InputField from "./Fields/InputField";
import { getPermWeeks } from "../utils/permSectionUtils";
import { color } from "../theme/standardTheme";
import InfoIcon from "./InfoIcon";
import Modal from "../containers/Modal";
import InnerModalPermSections from "./InnerModalPermSections";

const AddSectionTool = (props) => {
        
    const [expand, setExpand] = useState('none');
    const [permType, setPermType] = useState({ value: 'beggeFerie', label: 'Begge Ferie' });
    const [duration, setDuration] = useState('');

    useEffect(() => {
        const permWeeks = getPermWeeks(permType.value, props.model100);
        setDuration(permWeeks);
    }, [props.model100])

    function addSection(event) {
        event.stopPropagation();
        if (permType==='none' || duration==='') return;
        setExpand('shrink');
        const d = duration;
        const label = permType.label;
        props.addSection(d, label, permType.value);
        props.mixpanelTrackEvent('Add Period', {duration: d, type: label});
        //setDuration('');
        //setPermType('none');
    }

    function setPermTypeHandler(item) {
        const defaultWeeks = getPermWeeks(item.value, props.model100);
        setDuration(defaultWeeks)
        setPermType(item);
    }

    const titleStyle = {    
        marginTop: "6px",
        color: color.fontSecondary
    }
    const dropdownStyle = {    
        margin: "0px 20px 0px 0px"
    }
    const addButtonStyle = {    
        
    }

    return (<>
        <AddSectionToolContainer color={props.color.tertiary} onClick={() => setExpand('expand')} expand={expand}>
            <H5 style={titleStyle}>Legg til Permisjonsperiode <InfoIcon onClick={() => props.setShowPlannerInfo(true)}/></H5><div/>
            {(expand==='expand' || true)?<>
                <PermInputContainer>
                    <DropdownMenu
                        style={dropdownStyle}
                        permType={permType}
                        setPermType={setPermTypeHandler}
                        select={'beggeFerie'}
                    />
                    <H5 style={{margin: "0px 10px", justifySelf: "end", color: color.fontSecondary}}>Uker:</H5>
                    <InputField
                        type="number"
                        pattern="[0-9]*"
                        value={duration}
                        update={setDuration}
                        maxWidth={"70px"}
                    />
                </PermInputContainer>
                <TopicButton style={addButtonStyle} color={props.color.secondary} onClick={event => addSection(event)}>Legg til</TopicButton>
            </>:<></>}
        </AddSectionToolContainer>
    </>)
}

export default AddSectionTool;