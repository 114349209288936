import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../containers/Modal';
import InnerModalContainer from '../containers/InnerModalContainer';
import { H2, P } from '../theme/text';
import InputField from '../components/Fields/InputField';
import TopicButton from './Buttons/TopicButton';
import { color } from '../theme/standardTheme';
import { startLoginSession } from '../server/api/user';

const CloseButton = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    display: inline-block;
    padding: 8px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: ${color.fontSecondary};
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
`

const LoginModal = (props) => {
    // Add your component logic here

    const [email, setEmail] = useState('');
    const [foundUser, setFoundUser] = useState(null);
    const [falseEmail, setFalseEmail] = useState(false);
    const [oneTimeCode, setOneTimeCode] = useState('');
    const [oneTomeCodeError, setOneTimeCodeError] = useState(false);

    function verifiedEmail(){
        var lastChar = email.substring(email.length - 1);
        if(
            !email.includes('@') ||
            !email.includes('.') ||
            lastChar==='@' ||
            lastChar ==='.' ||
            email.indexOf('@') > email.lastIndexOf('.')
        ) return false;
        else return true;
    }

    const login = async () => {
        if(!verifiedEmail()){
            setFalseEmail(true);
            setFoundUser(null);
            return;
        }
        setFalseEmail(false);
        const user = await startLoginSession(email);
        if (user) {
            setFoundUser(user);
        } else {
            setFoundUser(false);
        }
    }

    const completeLogin = async () => {
        console.log('completing login');
        console.log('oneTimeCode:', oneTimeCode);
        console.log('foundUser.oneTimeCode:', foundUser.oneTimeCode);
        if (oneTimeCode.length !== 6) setOneTimeCodeError('Engangskoden må være 6 siffer');
        else if (Number(oneTimeCode) !== foundUser.oneTimeCode ) setOneTimeCodeError('Feil engangskode');
        else props.loadLogin({...foundUser});
    }

    return (
        <Modal show={props.show} onClick={props.close}>
            <InnerModalContainer style={{maxWidth: '600px'}} onClick={(event) => event.stopPropagation()}>
                <H2>{'Velkommen tilbake 🩶'}</H2>
                {foundUser?.success===true? <>
                    <P style={{fontSize: '14px', fontWeight: 'bold'}}>{"Supert! Vi har sendt deg en epost med en engangskode for å logge inn :-)"}</P>
                    <br></br>
                    <InputField
                        type='number'
                        placeholder = "XXXXXX"
                        update= {setOneTimeCode}
                        maxWidth = {'300px'}
                        label= "Engangskode:"
                    />
                    <TopicButton onClick={completeLogin} style={{margin: '20px'}} hoverColor={color.font}>Logg inn</TopicButton>
                    <P><strong>{oneTomeCodeError}</strong></P>
                    <br></br>
                    <P>Det kan ta noen minutter, husk å sjekk søppelpost!</P>
                </>:<>
                    <P style={{fontSize:"18px"}}>
                        Oppgi epost for å logge inn
                    </P>
                    <br/>
                    <InputField
                        label= "Epost:"
                        placeholder = "din epost"
                        update = {setEmail}
                        maxWidth = {'500px'}
                        type = {'email'}
                        autocomplete={"on"}
                        id="email"
                    />
                    <TopicButton onClick={login} style={{margin: '20px', minWidth: '200px'}} hoverColor={color.font}>Hent engangskode</TopicButton>
                    {falseEmail?
                        <P style={{fontSize: '14px', fontWeight: 'bold', color: 'black'}}>Du må oppgi en gyldig epost</P>
                    : null}
                    {foundUser===false?
                        <P style={{fontSize: '14px', fontWeight: 'bold', color: 'black'}}>Vi fant ingen bruker på denne eposten, sikker på at den er rett skrevet?</P>
                    : null}
                    <CloseButton onClick={props.close}>x</CloseButton>
                </>}
            </InnerModalContainer>
        </Modal>
    );
};

export default LoginModal;