import axios from 'axios'
import { appConfig } from '../../appConfig';

const host = appConfig.host;

async function testPost() {
    console.log('Testing post...');
    const endpoint = '/';
    const url = `${host}${endpoint}`;
    var success = false;
    try {
        const data = {
            testData: [1, 2, 3],
        }
        const result = await axios({
            method: "POST",
            url: url,
            headers: { 
                testHeader: 'testHeader'
            },
            data: data, // fetch with req.body
        });
        console.log('result: ', result);
        if (result.status === 200) success = true;
    } catch (e) {console.log(e)}
    finally {
        if (success){
            return true;
        } else return false;
    } 
}

async function subscribe(email, periods, sections, termDate, subscribeToMail) {
    const endpoint = '/pdf';
    const url = `${host}${endpoint}`;
    var success = false;
    try {
        const data = {
            periods: periods, 
            sections: sections,
            termDate: termDate,
            subscribeToMail: subscribeToMail
        }
        const result = await axios({
            method: "POST",
            url: url,
            headers: { 
                email: email
            },
            data: data, // fetch with req.body
        });
        console.log('result: ', result);
        success = result.data.success;
    } catch (e) {console.log(e)}
    finally {
        if (success){
            return true;
        } else return false;
    } 
}

export {
    subscribe,
    testPost,
}